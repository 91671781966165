import React from 'react';
import AdSense from 'react-adsense';

const AdSensePrimary = () => (
  <AdSense.Google
    client='ca-pub-7870884684574206'
    slot='9200453742'
  />
);

export default AdSensePrimary;